import React, { createContext } from "react";
import { setInLS, parseJWT } from "@flash-hros/utility";
import { removeTokenFromURL, shallowEqual } from "@Utils/index";
import {
  FlowCategory,
  FlowSubcategory,
  HiringCard,
  ResignationCard,
} from "server/src/types";
import { SpinnerContainer } from "./styles";
import { Loader } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "../api/client";
import { EmployeeModel } from "server/src/services/engagement/models";
import { getFromLS } from "@flash-tecnologia/hros-web-utility";

export interface TokenData {
  engagementId: string;
  cardId: string;
  employeeId: string;
  candidateId: string;
  companyId: string;
  name: string;
  type: string;
  category: FlowCategory;
  subcategory: FlowSubcategory;
  token: string;
}

export type User = EmployeeModel &
  Partial<{
    id: string;
    phoneNumber: string;
    email: string;
    documentNumber: string;
    economicGroupId: string;
  }>;

interface IContext extends TokenData {}

const defaultTokenData: TokenData = {
  engagementId: "",
  cardId: "",
  employeeId: "",
  candidateId: "",
  companyId: "",
  name: "",
  type: "",
  category: FlowCategory.hiring,
  subcategory: FlowSubcategory.clt,
  token: "",
};

const Context = React.createContext<IContext>(defaultTokenData);
const ContextProvider = ({ children }: { children: React.ReactElement }) => {
  const [hasSetup, setHasSetup] = React.useState<boolean>(false);
  const [tokenData, setTokenData] = React.useState<TokenData>({
    engagementId: "",
    cardId: "",
    employeeId: "",
    candidateId: "",
    companyId: "",
    name: "",
    type: "",
    category: FlowCategory.hiring,
    subcategory: FlowSubcategory.clt,
    token: "",
  });

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromURL = urlParams.get("token");
    const token = tokenFromURL || getFromLS("wizard-token");
    if (tokenFromURL) {
      setInLS({ key: "wizard-token", value: token });
      removeTokenFromURL(urlParams);
    }

    setHasSetup(true);
    if (!token) return;

    const decodedToken = parseJWT({ token });
    const parsedToken: TokenData = {
      engagementId: decodedToken?.engagementId || "",
      cardId: decodedToken.cardId,
      category: decodedToken.category ?? getCategoryFromPage(),
      companyId: decodedToken.companyId,
      employeeId: decodedToken.employeeId || "",
      candidateId: decodedToken.candidateId || "",
      name: decodedToken.name || "",
      subcategory: decodedToken.subcategory || "",
      type: decodedToken.type,
      token: token,
    };
    if (shallowEqual(token, parsedToken)) return;
    setTokenData(parsedToken);
  }, []);

  const shouldRequestData =
    !!tokenData.cardId &&
    (!!tokenData.category || !!getCategoryFromPage()) &&
    pageShouldRequest();

  const { data, isLoading } = trpc.hiring.getFlowCardData.useQuery(
    {
      cardId: tokenData.cardId,
      category: tokenData.category as FlowCategory,
    },
    {
      enabled: shouldRequestData,
      onSuccess: (data) => {
        if (!data.allowed) {
          window.location.href = "/wizard/invalid?type=expired";
        }
      },
      onError: () => {
        window.location.href = "/wizard/invalid";
      },
    }
  );

  function getCategoryFromPage(): FlowCategory | undefined {
    const path = window.location.pathname;
    const page: string = path.split("/")[2];

    switch (page) {
      case "aso":
        return FlowCategory.hiring;
      case "resignationAso":
        return FlowCategory.resignation;
      default:
        return undefined;
    }
  }

  function pageShouldRequest(): boolean {
    const path = window.location.pathname;
    const page: string = path.split("/")[2];

    return !["invalid", "finished"].includes(page);
  }

  if (!hasSetup || (shouldRequestData && isLoading))
    return (
      <SpinnerContainer>
        <Loader variant="primary" size="large" />
      </SpinnerContainer>
    );
  return (
    <Context.Provider
      value={{
        category: data?.value?.flow.category ?? FlowCategory.hiring,
        subcategory: data?.value?.flow.subcategory ?? FlowSubcategory.clt,
        cardId: data?.value?.card._id ?? "",
        candidateId: (data?.value?.card as HiringCard)?.candidateId ?? "",
        companyId: data?.value?.card.companyId ?? "",
        employeeId: (data?.value?.card as ResignationCard)?.employeeId ?? "",
        engagementId: tokenData.engagementId,
        name: tokenData.name,
        type: tokenData.type,
        token: tokenData.token,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const UserContext = createContext<User | null>(null);

export { ContextProvider, Context };
