import {
  Modal as ModalUiv2,
  Button,
  LinkButton,
  Icons,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Divider } from "../Divider";
import { ModalInterface } from "./types";
const { Root: ModalRoot } = ModalUiv2;
import {
  Body,
  CloseButton,
  Footer,
  IconContainer,
  Title,
  SubTitle,
  Description,
  MainIcon,
} from "./styles";

const Modal = ({
  children,
  footer,
  onClose,
  open,
  title,
  subTitle,
  description,
  closeButton,
  iconName,
  iconStatus,
}: ModalInterface) => {
  const handleText = (
    text: string,
    type: "title" | "subtitle" | "description"
  ) => {
    const splitedText = text?.split("<br/>");
    return (
      <>
        {splitedText?.map((word, index) => {
          if (type === "title")
            return (
              <Title key={index} status={iconStatus} variant="body3">
                {word}
              </Title>
            );
          if (type === "subtitle")
            return (
              <SubTitle key={index} variant="headline6">
                {word}
              </SubTitle>
            );
          return (
            <Description key={index} variant="body3">
              {word}
            </Description>
          );
        })}
      </>
    );
  };
  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      children={
        children ? (
          children
        ) : (
          <Body>
            {closeButton ? (
              <CloseButton onClick={onClose}>
                <Icons
                  name="IconX"
                  size={18}
                  color={"var(--color-neutral-40"}
                />
              </CloseButton>
            ) : null}
            {iconName ? (
              <IconContainer status={iconStatus}>
                <MainIcon
                  status={iconStatus || "info"}
                  name={iconName}
                  size={60}
                  onClick={onClose}
                />
              </IconContainer>
            ) : null}
            {title ? (
              <>
                <Divider spacing="quark" />
                {handleText(title, "title")}
              </>
            ) : null}
            {subTitle ? (
              <>
                <Divider spacing="quark" />
                {handleText(subTitle, "subtitle")}
              </>
            ) : null}
            {description ? (
              <>
                <Divider spacing="xxs3" />
                {handleText(description, "description")}
              </>
            ) : null}
          </Body>
        )
      }
      footer={
        footer?.children ? (
          footer?.children
        ) : footer?.submitButton || footer?.closeButton ? (
          <Footer>
            {footer?.closeButton ? (
              <div>
                <LinkButton
                  onClick={footer?.closeButton?.onClick}
                  variant={footer?.closeButton?.variant || "default"}
                  disabled={footer?.closeButton?.disabled}
                >
                  {footer?.closeButton?.label}
                </LinkButton>
              </div>
            ) : null}
            {footer?.submitButton ? (
              <div style={{ marginLeft: footer?.closeButton ? "10px" : "0px" }}>
                <Button
                  variant={footer?.submitButton?.variant || "primary"}
                  size={footer?.submitButton?.size || "large"}
                  onClick={footer?.submitButton?.onClick}
                  disabled={footer?.submitButton?.disabled}
                  loading={footer?.submitButton?.loading}
                >
                  {footer?.submitButton?.label}
                </Button>
              </div>
            ) : null}
          </Footer>
        ) : null
      }
    />
  );
};

export { Modal };
