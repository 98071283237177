import { Icons } from "@flash-tecnologia/hros-web-ui-v2";
import {
  BackButton,
  Container,
  ContinueButton,
  Wrapper,
  StyledLinkButton,
} from "./styled";

type ButtonProps = {
  title?: React.ReactNode;
  callback?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  loading?: boolean;
  hasToShow?: boolean;
  width?: string;
};

export type FooterProps = {
  cancelProps?: ButtonProps;
  goBackProps?: ButtonProps;
  confirmProps?: ButtonProps;
  skipProps?: ButtonProps;
  draftProps?: ButtonProps;
  disable?: boolean;
  hasToShow?: boolean;
};

export const Footer = ({
  cancelProps,
  goBackProps,
  confirmProps,
  skipProps,
  draftProps,
}: FooterProps) => {
  const renderButton = (
    props: ButtonProps | undefined,
    defaultTitle: string,
    Component: React.ElementType,
    icon?: React.ReactNode
  ) => {
    if (!props) return null;

    const { title, callback, disabled, loading, hasToShow, width } = props;

    if (hasToShow === false) return null;

    return (
      <Component
        variant="primary"
        loading={loading || false}
        disabled={disabled || loading || false}
        onClick={callback}
        style={{ width }}
      >
        {title || (
          <>
            {icon}
            {defaultTitle}
          </>
        )}
      </Component>
    );
  };

  return (
    <Container>
      <Wrapper>
        {renderButton(cancelProps, "Cancelar", StyledLinkButton)}
        {renderButton(draftProps, "Sair e salvar rascunho", StyledLinkButton)}
        {renderButton(skipProps, "Pular e finalizar", StyledLinkButton)}
      </Wrapper>
      <Wrapper>
        {renderButton(
          goBackProps,
          "Voltar",
          BackButton,
          <Icons name="IconArrowLeft" />
        )}
        {renderButton(
          confirmProps,
          "Concluir",
          ContinueButton,
          <Icons name="IconCheck" fill="transparent" />
        )}
      </Wrapper>
    </Container>
  );
};
