// @filename: client.ts
import { getFromLS } from "@flash-tecnologia/hros-web-utility";
import { QueryClient } from "@tanstack/react-query";
import { createTRPCReact, httpBatchLink } from "@trpc/react-query";
import type { AppRouter } from "server";

export const trpc = createTRPCReact<AppRouter>();

export const queryClient = new QueryClient();
export const trpcClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${process.env.BFF_URL}/trpc`,
      headers: async () => {
        const token = await getFromLS("wizard-token");
        return {
          Authorization: token,
        };
      },
    }),
  ],
});
