import styled from "styled-components";

import { IconButton } from "@flash-tecnologia/hros-web-ui-v2";

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  background: #fff;

  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  margin-bottom: 40px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BoxContainer = styled.div<{ selected: boolean; checked: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;

  background: ${({ theme, selected, checked }) =>
    checked
      ? theme.colors.neutral[95]
      : selected
      ? theme.colors.secondary[99]
      : theme.colors.neutral[100]};
  border: 1px solid
    ${({ theme, selected, checked }) =>
      checked
        ? "transparent"
        : selected
        ? theme.colors.secondary[50]
        : theme.colors.neutral[80]};
  border-radius: 8px;

  :last-child {
    margin-bottom: 0px;
  }
`;

export const BoxSubContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const BoxIconChecked = styled(IconButton)`
  margin-left: 12px;
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.feedback.success[90]};
      border-color: ${({ theme }) => theme.colors.feedback.success[70]};
      pointer-events: none;
      svg {
        stroke: ${({ theme }) => theme.colors.feedback.success[10]};
      }
    }
  }
`;

export const BoxIcon = styled(IconButton)`
  margin-left: 12px;
  && {
    &.MuiButtonBase-root {
      background-color: ${({ theme }) => theme.colors.secondary[95]};
      border-color: ${({ theme }) => theme.colors.secondary[95]};
      pointer-events: none;
      svg {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export const IconButtonContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary[95]};
  border-radius: 50%;
  padding: 6px;
`;

export const Divider = styled.div`
  width 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral[90]};
`;

export const ProgressBarContainer = styled.div<{ progressValue: number }>`
  width: 100%;
  height: 2px;
  border-radius: 100px;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary} 0%,
    ${({ theme }) => theme.colors.primary}
      ${({ progressValue }) => progressValue}%,
    ${({ theme }) => theme.colors.neutral[90]}
      ${({ progressValue }) => progressValue}%,
    ${({ theme }) => theme.colors.neutral[90]} 100%
  );
`;

export const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral[100]};
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  margin-left: 6px;
  margin-right: 6px;
  background: ${({ theme }) => theme.colors.neutral[50]};
  border-radius: 50%;
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RadioContainer = styled.div<{
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? "4px" : "0px")}};
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "4px" : "0px")}};
  border-radius: 12px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.neutral[95] : "transparent"};
  padding: 4px 6px;
`;

export const CheckboxContainer = styled.div<{
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  isHidden?: boolean;
  selected: boolean;
}>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ hasMarginBottom }) => (hasMarginBottom ? "4px" : "0px")}};
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? "4px" : "0px")}};

  border-radius: 12px;
  background: ${({ theme, selected }) =>
    selected ? theme.colors.neutral[95] : "transparent"};
  padding: 4px 6px;
  span.MuiButtonBase-root {
    visibility: ${({ isHidden }) => (isHidden ? "hidden" : "none")}};;
  }
`;

export const ErrorAsterisk = styled.span`
  color: ${({ theme }) => theme.colors.feedback.error[40]};
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
`;
