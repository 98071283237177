import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page from "../components/Page";
import { SurveyAnswer } from "../pages";
import AuthGuard from "./AuthGuard";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route path="/wizard-engagement" element={<Page />} />
          <Route
            path={"/wizard-engagement/survey/answer/:step/:engagementId"}
            element={<SurveyAnswer />}
          />
          <Route path="*" element={<div>Page not found</div>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
