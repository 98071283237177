import { Button, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";

import {
  ButtonContainer,
  Container,
  FlexCenter,
  IconContainer,
  MainContainer,
  PageContents,
  Subtitle,
  Title,
} from "./styles";

import { useTheme } from "styled-components";

const AlreadyAnswered = () => {
  const theme = useTheme();

  return (
    <MainContainer>
      <PageContents>
        <FlexCenter>
          <Container>
            <IconContainer>
              <Icons
                name="IconCheck"
                size={64}
                fill={"transparent"}
                color={theme.colors.primary}
              />
            </IconContainer>
            <Title variant="headline6">Sua pesquisa já foi respondida.</Title>
            <Subtitle variant="body3">
              Acesse a plataforma para visualizar suas respostas.
            </Subtitle>
          </Container>
          <ButtonContainer>
            <Button
              variant="primary"
              size="large"
              onClick={() => {
                window.open(
                  `${process.env.HROS_URL}/engagement/my-surveys`,
                  "_blank"
                );
              }}
            >
              <Typography
                variant="body3"
                color="neutral.100"
                children={"Acessar Minhas pesquisas"}
                style={{ fontWeight: 700 }}
              />
            </Button>
          </ButtonContainer>
        </FlexCenter>
      </PageContents>
    </MainContainer>
  );
};

export default AlreadyAnswered;
