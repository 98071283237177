import { IURL } from "./employee";

export interface IFile {
  path: string;
  key: string;
  type: "internal" | "external";
}

export interface IRichText {
  html?: any;
  json?: any;
}

export interface IRecord {
  organization?: string;
  role?: string;
  assignments?: IRichText;
  startDate?: string;
  endDate?: string;
}

export interface HiringFlowList {
  hiringType: "clt" | "pj" | "internship";
  name: string;
  setupId: string;
  _id: string;
}

export enum FlowSubcategory {
  clt = "clt",
  pj = "pj",
  internship = "internship",
}

export enum FlowCategory {
  hiring = "hiring",
  resignation = "resignation",
}

export interface Flow {
  _id: string;
  name: string;
  type: any;
  active: boolean;
  companyId: string;
  setupId: string;
  columns?: Column[];
  category: FlowCategory;
  subcategory?: FlowSubcategory;
}

export interface Column {
  _id: string;
  name: string;
  locked: true;
  editable: boolean;
  color: string;
  description?: string;
  position: number;
  hidden: boolean;
  tags: any;
  cards: any;
}

export interface ChecklistData {
  _id: string;
  columnId: string;
  flowId: string;
  companyId: string;
  items: string[];
}

type StatusHistory = {
  status: string;
  columnId: string;
};

export type EmailLink = {
  name: string;
  columnId: string;
  link: string;
  token?: string;
  expired?: boolean;
};

export type ChecklistItemData = {
  checked: boolean;
  label: string;
};

export type ChecklistCard = {
  columnId: string;
  items: ChecklistItemData[];
};

export type S3File = {
  key: string;
  path: string;
  value?: string;
  type: "internal" | "external";
};

export interface Author {
  _id: string;
  name: string;
  profilePicture: S3File;
}

export enum HiringLimitDateEnum {
  proposalLetter_Pj = "proposal-letter-pj",
  proposalLetter_Clt = "proposal-letter-clt",
  proposalLetter_Estag = "proposal-letter-estag",
  sendDocuments_Pj = "send-documents-pj",
  sendDocuments_Clt = "send-documents-clt",
  sendDocuments_Estag = "send-documents-estag",
  signContract_Pj = "sign-contract-pj",
  signContract_Clt = "sign-contract-clt",
  signContract_Estag = "sign-contract-estag",
  signContract_Resignation = "sign-contract-resignation",
  aso_Ctl = "aso-ctl",
  aso_Estag = "aso-estag",
}

export enum ResignationLimitDateEnum {
  aso_Resignation = "aso-resignation",
}

export interface ILimitDate<T> {
  step: T;
  date: string;
}

export interface Follower {
  _id: string;
  name: string;
  profilePicture?: S3File;
}

export enum ResignationExamStatus {
  pending = "pending",
  sent = "sent",
  filled = "filled",
  manual = "manual",
}

export enum ValidationStatusOptions {
  approved = "approved",
  declined = "declined",
  refused = "refused",
  waiting = "waiting",
}

export type ValidationResignationExam = {
  status: ValidationStatusOptions;
  statusComment: string;
};

export interface ResignationExam {
  validation?: ValidationResignationExam;
  medicalReferral?: S3File;
  limitDate?: string;
  date?: string;
  status?: ResignationExamStatus;
  file?: S3File;
}

export enum AdmissionalExamStatus {
  sent = "sent",
  resent = "resent",
  filled = "filled",
  declined = "declined",
  approved = "approved",
}

export interface AdmissionalExam {
  validation?: {
    status: "approved" | "declined" | "waiting";
    statusComment: string;
  };
  medicalReferral?: S3File;
  limitDate?: string;
  date?: string;
  status?: AdmissionalExamStatus;
  file?: S3File;
}

export interface Card {
  _id: string;
  flowId: string;
  name: string;
  description: string;
  status: string;
  code: number;
  color: string;
  active: boolean;
  position: number;
  columnId: string;
  companyId: string;
  companyName: string;
  setupId: string;
  metadata?: any;
  version: number;
  followers?: Follower[];
  emailLink: EmailLink[];
  statusHistory: StatusHistory[];
  checklists: ChecklistCard[];
  comments: Comment[];
  authors: Author[];
  limitDates: ILimitDate<any>[];
}

export interface HiringCard extends Card {
  documentNumber: string;
  email: string;
  candidateId: string;
  modelProposedLetterId: string;
  approvalStatus?: "approved" | "refused";
  approvalCommentary?: string;
  updatedAt: string;
  createdAt: string;
  formConfigId: string;
  formExtensionId: string;
  admissionalExam: AdmissionalExam;
  attachments: any;
  limitDates: ILimitDate<HiringLimitDateEnum>[];
}

export interface ResignationCard extends Card {
  employeeId: string;
  resignationInterviewFile?: S3File;
  resignationInterviewObservation?: string;
  asoFile?: S3File;
  medicalReferral?: S3File;
  asoDate: string;
  resignationExam: ResignationExam;
  asoResult: string;
  lastWorkingDate: string;
  serveNoticePeriod?: boolean;
  resignation: string;
  resignationEmail?: string;
  resignationReason?: string;
  resignationType: string;
  resignationLetter?: S3File;
  resignationRequestDate: string;
  limitDates: ILimitDate<ResignationLimitDateEnum>[];
}

export interface Contract {
  _id: string;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  contractDuration: any;
  flowCardId: string;
  status: SignStatus;
  template: Template[];
  signature: Signature[];
  contractOutput: ContractOutput[];
  category: string;
  ip?: string;
}

export interface Template {
  key: string;
  path: string;
  type: string;
  value?: string;
}

export enum SignType {
  candidate = "candidate",
  company = "company",
  witness = "witness",
  institution = "institution",
  employee = "person",
}

export enum SignStatus {
  pending = "Pending",
  signed = "Signed",
}

export enum SignatureSignStatus {
  pending = "Pending",
  signed = "Signed",
  external = "External",
}

export type Signature = {
  candidateId?: string;
  employeeId?: string;
  name: string;
  email: string;
  phone?: string;
  type: SignType;
  status: SignatureSignStatus;
  token?: string;
  signedAt?: string;
  signatureFile?: any;
  ip?: string;
  confirmationInfo?: ConfirmationInfo;
};

export interface SignatureFile {
  key: string;
  path: string;
  origin: string;
}

export interface ConfirmationInfo {
  name: string;
  documentNumber: string;
  email: string;
}

export interface ContractOutput {
  key: string;
  path: string;
  type: string;
  value?: string;
  version: number;
}

export interface IAcademicRecord {
  course?: string;
  institution?: string;
  graduationLevel?: string;
  startDate?: string;
  endDate?: string;
}

export interface IProposalLetterInfo {
  aboutCompany?: IURL[];
  benefits?: IURL[];
  hiringType?: string;
  hiringDate?: string;
  proposalStatus?: string;
  refuseReason?: string;
  role?: {
    _id: string;
    name: string;
  };
  department?: {
    _id: string;
    name: string;
  };
  group?: {
    _id: string;
    name: string;
  };
  assignments?: string;
  salary?: number;
  variableSalary?: string;
  workload?: string;
  workship?: string;
}

export interface IInternship {
  institutionName?: string;
  courseName?: string;
  startDate?: string;
  endDate?: string;
  workship?: string;
  workload?: string;
}

export interface CandidateDocumentField {
  fieldId: string;
  customFieldId: string;
  type: FieldType;
  created?: string;
  expirationDate?: string | null;
  formGroupId: string;
  value: string | number | boolean | IFile | null;
}

export interface IDocumentValidation {
  formGroupId: string;
  status: "approved" | "declined" | "waiting";
  statusComment?: string;
}

export interface Leader {
  _id: string;
  name: string;
  profilePicture?: IURL;
}

export interface Candidate {
  _id: string;
  active: boolean;
  termAccepted: boolean;
  nationality?: string;
  jobId?: string;
  companyId: string;
  employeeId?: string;
  birthDate?: string;
  registrationDate?: string;
  name: string;
  socialName?: string;
  legalName?: string;
  documentNumber: string;
  leader?: Leader;
  cnpj?: string;
  gender?: string;
  race?: string;
  maritalStatus?: string;
  schooling?: string;
  email: string;
  phone?: string;
  isPCD: boolean;
  disabilityType?: string;
  linkedinProfileUrl?: string;
  indicator?: string;
  jobUrl?: string;
  curriculum?: IFile;
  academicCareer?: IAcademicRecord[];
  professionalCareer?: IRecord[];
  extracurricularActivities?: IRecord[];
  documents?: CandidateDocumentField[];
  proposalLetterInfo?: IProposalLetterInfo;
  internship?: IInternship;
  validations?: IDocumentValidation[];
}

export interface Dependent {
  _id: string;
  active: boolean;
  name: string;
  birthDate: string;
  kinship: string;
  candidateId: string;
  values: FieldValue[];
  validations: IDocumentValidation[];
}

export interface FieldValue extends FieldConfig {
  value?: any;
  candidateId: string;
}

export interface ConfirmationData {
  candidateId: string;
  formConfigId: string;
  extensionId?: string;
  candidateFields: FieldValue[];
  documentFields: ConfirmationDocumentsWithValue[];
  dependents?: ConfirmationDependent[];
}

export interface ConfirmationDependent {
  _id: any;
  name: string;
  active: boolean;
  kinship: string;
  birthDate: string;
  candidateId: string;
  formConfig: string;
  extensionId: string;
  documents: ConfirmationDocumentsWithValue[];
}

export interface ConfirmationDocumentsWithValue {
  _id: string;
  formConfigId: string;
  extensionId?: string;
  name: string;
  title: string;
  description?: string;
  fillInstruction?: {
    html: string;
    json?: string;
  };
  enabled: boolean;
  fields: FieldValue[];
  validation?: IDocumentValidation;
}

export interface FormConfig {
  _id: string;
  name: string;
  formGroups: FormGroup[];
  companyId?: string;
  baseId?: string;
  extensionId?: string;
  type: "base" | "extension";
}

export interface FormGroup {
  _id: string;
  title?: string;
  order?: number;
  enabled?: boolean;
  category: "employee" | "dependent";
  type?: "base" | "extension";
  description?: string;
  fillInstruction?: {
    html: string;
    json?: string;
  };
  fields?: FieldConfig[];
}

export enum FieldType {
  Text = "text",
  Number = "number",
  Date = "date",
  Boolean = "boolean",
  File = "file",
  Array = "array",
}

export enum Component {
  ShortText = "shortText",
  LongText = "longText",
  Number = "number",
  Attachment = "attachment",
  Link = "link",
  DatePicker = "datePicker",
  TimePicker = "timePicker",
  Select = "select",
  MultiSelect = "multiSelect",
  Checkbox = "checkbox",
}

export interface FieldConfig {
  fieldId: string;
  customFieldId: string;
  documentTypeId?: string;
  formGroupId: string;
  order?: number;
  fieldType: FieldType;
  options?: any[];
  component: Component;
  placeholder: string;
  fieldRule?: FieldRule;
  type: "base" | "extension";
  enabled: boolean;
}

export interface FieldRule {
  validations?: Comparison[];
  enable?: Comparison[];
  required?: boolean;
  fieldId: string;
}

export enum ComparisonType {
  Equal = "equal",
  NotEqual = "notEqual",
  GreaterThan = "greaterThan",
  GreaterThanOrEqual = "greaterThanOrEqual",
  LesserThan = "lesserThan",
  LesserThanOrEqual = "lesserThanOrEqual",
  Includes = "includes",
  NotIncludes = "notIncludes",
  Exists = "exists",
  NotExists = "notExists",
  Or = "or",
  And = "and",
}

export interface Comparison {
  _id?: string;
  compared?: CompareField;
  comparedTo?: CompareField;
  comparisonType: ComparisonType;
  comparisons?: Comparison[];
}

export interface CompareField {
  _id?: string;
  name?: string;
  sensitive?: boolean;
  type: FieldType;
  isFixedValue?: boolean;
  value?: any;
}

export interface FormConfigList {
  extensionId: string;
  name: string;
  numberOfGroups: number;
  _id: string;
  default: boolean;
}
