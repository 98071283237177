import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f6f8f9;
  overflow: hidden;
`;

export const PageContents = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 37px 24px;

  @media screen and (max-width: ${({ theme }) =>
      `${theme.breakpoints.values.md}px`}) {
    padding: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 645px;

  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  padding: 44px 112px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[90]};
`;

export const ButtonContainer = styled.div`
  width: 100%;
  max-width: 645px;

  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.neutral[20]};
  margin: 16px 0px;

  max-width: 421px;
  text-align: center;
  font-weight: 700;
`;

export const Subtitle = styled(Typography)`
  text-align: center;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[50]};
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const IconContainer = styled.div`
  width: 80px;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
`;
